import React from 'react'
import Cx from 'classnames'
import styles from './styles'
import { TimelineMax } from 'gsap'
import LogoApp from '/components/LogoApp'
import vertexShader from './vert.glsl'
import fragmentShader from './frag.glsl'


class Circles extends LogoApp {
  updateUniforms(uniforms) {
    uniforms.shape = { value: 0 }
    let tl = new TimelineMax({
      repeat: Infinity
    })
    let dur = 0.4
    let wait = 0.7
    let ease = Quad.easeOut
    tl.to(uniforms.shape, dur, {
      value: 0.25,
      ease,
    }, wait)
    tl.to(uniforms.shape, dur, {
      value: 0.5,
      ease,
    }, '+=' + wait)
    tl.to(uniforms.shape, dur, {
      value: 0.75,
      ease,
    }, '+=' + wait)
    tl.to(uniforms.shape, dur, {
      value: 1,
      ease,
    }, '+=' + wait)
  }

  getVertexShader() {
    return vertexShader
  }

  getFragmentShader() {
    return fragmentShader
  }
}

export default Circles