import React from 'react'
import { Link } from 'react-router-dom'
import BaseLink from '../BaseLink'
import GeomShape from '/components/Shapes/GeomShape'
import { isMobileOS } from '/helpers'
import { URLS } from '/constants'

const IS_MOBILE = isMobileOS()

class ShapeLink extends BaseLink {
  constructor(props) {
    super(props)
    this.state = {
      over: false,
      width: 100,
      rotation: Math.random() * 180,
      delay: Math.random()
    }
  }

  render() {
    let LinkTag = 'a'
    let linkProps = {
      href: this.props.url,
      target: '_blank',
    }
    if (this.props.hotline && !IS_MOBILE) {
      LinkTag = Link
      linkProps = {
        to: URLS.HOTLINE,
      }
    }
    if (this.props.comingsoon) {
      LinkTag = 'div'
      linkProps = {}
    }
    return <LinkTag
      {...linkProps}
      className={this.props.className}
      style={this.props.style}
      onMouseEnter={this.onMouseEnter}
      onMouseLeave={this.onMouseLeave}>
      <GeomShape
        width={this.state.width}
        rotation={this.state.rotation}
        img={this.props.img}
        inactive={this.props.comingsoon}
        fill={this.props.fill}
        shape={this.props.shape}
        visible={this.props.visible}
        imgVisible={this.state.over ? 1 : 0} />
    </LinkTag>
  }
}

export default ShapeLink
