import { Scene, OrthographicCamera, WebGLRenderer} from 'three'
import { isIOSChrome } from '/helpers'

const IOS_CHROME = isIOSChrome()

class ThreeApp {
  constructor(settings) {
    // Setup
    this.width = 0
    this.height = 0
    this.resizeTick = false
    this.canvas = settings.canvas
    this.settings = settings
    this.setupThree(settings)
    this.resize(true)
    this.addEvents()
  }

  addEvents() {
    window.addEventListener('resize', this.onWindowResize)
  }

  removeEvents() {
    window.removeEventListener('resize', this.onWindowResize)
  }

  setupThree(settings) {
    this.scene = new Scene()
    this.camera = new OrthographicCamera(-1, 1, 1, -1, 1, 1000)
    this.renderer = new WebGLRenderer(settings)
  }

  getRendererSize() {
    if (IOS_CHROME) {
      let width = document.documentElement.clientWidth
      let height = document.documentElement.clientHeight
      if (width > height) height = screen.width - 20
      else height = screen.height - 40
      return {
        width,
        height
      }
      // <div id="debug" style="position:fixed;top:0;left:0;color:red;"></div>
      // document.getElementById('debug').innerHTML = ['s', screen.width, screen.height, 'i', window.innerWidth, window.innerHeight, 'd', document.documentElement.clientWidth, document.documentElement.clientHeight].join(' ')
    }
    let rect = this.canvas.parentElement.getBoundingClientRect()
    return {
      width: rect.width,
      height: rect.height,
    }
  }

  resize(doNotBubble) {
    // Reisze
    let rect = this.getRendererSize()
    if (rect.width === this.width && rect.height === this.height) return
    this.width = rect.width
    this.height = rect.height
    this.renderer.setPixelRatio(Math.min(2, window.devicePixelRatio))
    this.renderer.setSize(rect.width, rect.height)
    this.camera.updateProjectionMatrix()
    if (this.settings.onResize && !doNotBubble) this.settings.onResize(this.width, this.height)
    this.resizeTick = false
    this.start()
  }

  tick() {
    let t = Date.now() - this.startTime
    if (this.onTick) this.onTick(t)
    this.renderer.render(this.scene, this.camera)
  }

  loop = () => {
    this.ref = window.requestAnimationFrame(this.loop)
    this.tick()
  }

  start() {
    this.startTime = Date.now()
    this.loop()
  }

  stop() {
    window.cancelAnimationFrame(this.ref)
  }

  onWindowResize = () => {
    if (!this.resizeTick) {
      this.resizeTick = true
      this.stop()
      window.requestAnimationFrame(() => this.resize())
    }
  }

  destroy() {
    this.stop()
    this.removeEvents()
  }
}

export default ThreeApp