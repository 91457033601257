import React from 'react'
import ReactDOM from 'react-dom'
import Cx from 'classnames'
import styles from './styles'

export default class extends React.Component {
  componentWillMount() {
    document.documentElement.style.overflow = 'hidden'
  }

  componentWillUnmount() {
    document.documentElement.style.overflow = 'auto'
  }

  onClick = (e) => {
    if (e.target === this._el) {
      if (this.props.onOutsideClick) this.props.onOutsideClick()
    }
  }

  render() {
    return ReactDOM.createPortal(
      <div className={styles.container} onClick={this.onClick} ref={c=>{this._el=c}}>
        <div className={Cx(styles.overlay, this.props.overlayClassName)} style={this.props.overlayStyle} />
        { this.props.children }
      </div>,
      document.getElementById('modal'),
    )
  }
}