import React from 'react'
import Cx from 'classnames'
import styles from './styles'
import { TweenLite } from 'gsap'
import { TextureLoader, PlaneBufferGeometry, RawShaderMaterial, Mesh, Vector2, Vector3 } from 'three'
import ThreeApp from '/components/ThreeApp'
import { ease } from '/helpers'
import logo from '/assets/images/logo.png'
import logo_1024 from '/assets/images/logo_1024.png'
import logo_p from '/assets/images/logo_p.png'
import logo_p_1024 from '/assets/images/logo_p_1024.png'


class LogoApp extends React.Component {
  componentDidMount() {
    this.three = new ThreeApp({
      canvas: this._canvas,
      onResize: this.onCanvasResize,
    })
    this.buildScene()
    this.three.start()
    this.three.onTick = this.onTick
  }

  componentDidUpdate() {
    let c0 = this.props.colors[0]
    let c1 = this.props.colors[1]
    TweenLite.to(this.uniforms.color0.value, 0.3, {
      x: c0[0] / 255,
      y: c0[1] / 255,
      z: c0[2] / 255,
      ease: Quad.easeInOut,
    })
    TweenLite.to(this.uniforms.color1.value, 0.3, {
      x: c1[0] / 255,
      y: c1[1] / 255,
      z: c1[2] / 255,
      ease: Quad.easeInOut,
    })
  }

  componentWillUnmount() {
    if (this.three) {
      this.three.destroy()
    }
  }

  updateUniforms(uniforms) {}

  getVertexShader() {}

  getFragmentShader() {}

  getTexture() {
    if (this.three.width / this.three.height >= 1.1) return this.texture_landscape
    return this.texture_portrait
  }
  
  buildScene() {
    let c0 = this.props.colors[0]
    let c1 = this.props.colors[1]
    this.texture_landscape = new TextureLoader().load(this.three.width < 767 ? logo_1024 : logo)
    this.texture_portrait = new TextureLoader().load(this.three.width < 767 ? logo_p_1024 : logo_p)
    let geometry = new PlaneBufferGeometry(2, 2)
    let uniforms = {
      screenAspect: { value: this.three.width / this.three.height },
      mouse: { value: new Vector3(0.5, 0.5, 0.0) },
      color0: { value: new Vector3(c0[0], c0[1], c0[2]).divideScalar(255) },
      color1: { value: new Vector3(c1[0], c1[1], c1[2]).divideScalar(255) },
      time: { value: 0 },
      logo: { value: this.getTexture() },
      aspect: { value: 2048 / 2048},
    }
    this.updateUniforms(uniforms)
    let material = new RawShaderMaterial({
      uniforms,
      vertexShader: this.getVertexShader(),
      fragmentShader: this.getFragmentShader(),
    })
    let plane = new Mesh(geometry, material)
    this.mesh = plane
    this.three.scene.add(plane)
    this.three.camera.position.z = 5
    this.uniforms = uniforms
  }

  onTick = (t) => {
    this.uniforms.time.value = t / 70000
    this.uniforms.screenAspect.value = this.three.width / this.three.height
    this.uniforms.mouse.value.x = ease(this.uniforms.mouse.value.x, this.props.mouseX, 0.1)
    this.uniforms.mouse.value.y = ease(this.uniforms.mouse.value.y, this.props.mouseY, 0.1)
    this.uniforms.mouse.value.z = ease(this.uniforms.mouse.value.z, this.props.scroll, 0.25)
  }

  onCanvasResize = () => {
    let texture = this.getTexture()
    if (texture !== this.uniforms.logo.value) {
      this.uniforms.logo.value = texture
    }
  }

  stop() {
    this.three.stop()
  }

  render() {
    return <div className={styles.container}>
      <canvas className={styles.canvas} ref={c=>{this._canvas=c}} />
    </div>
  }
}

export default LogoApp