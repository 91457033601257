import React from "react";
import Cx from "classnames";
import styles from "./styles";
import Links from "/components/Links";

const NAMES = [
  [
    "ben berkley",
    "cole bolton",
    "gleb denisov",
    "matt hunziker",
    "louisa kellogg",
    "dave kornfeld",
    "dan mcgraw",
  ],
  [
    "jenny nellis",
    "jason roeder",
    "helena schlesinger",
    "marnie shure",
    "natalie sun",
    "lindsey willis",
  ],
];

class Credits extends React.Component {
  componentDidUpdate() {
    this._el.scrollTop = this.props.scroll;
  }

  getRef = (c) => {
    this._el = c;
    this.props.innerRef && this.props.innerRef(c);
  };

  render() {
    return (
      <div
        className={Cx(styles.container, {
          [styles.visible]: this.props.visible,
        })}
        style={this.props.style}
        ref={this.getRef}
      >
        <div className={styles.shapes}>
          <Links
            visible={this.props.visible}
            shape={this.props.shape}
            color={this.props.shapesColor}
            style={this.props.shapesStyle}
            key={this.props.shape}
          />
        </div>
        <div className={styles.credits}>
          <h1>
            Thud was a short-lived comedy company. It was very aptly named.
          </h1>
          <p>
            <a href="mailto:contact@thud.com">contact@thud.com</a>
          </p>
          <div className={styles.names}>
            {NAMES.map((list, i) => {
              return (
                <ul key={i}>
                  {list.map((n, j) => (
                    <li
                      style={{ animationDelay: 1.25 + 0.1 * (i * 7 + j) + "s" }}
                      key={j}
                    >
                      {n}
                    </li>
                  ))}
                </ul>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Credits;
