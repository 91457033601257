import React from 'react'
import Cx from 'classnames'
import { Route, withRouter } from 'react-router-dom'
import styles from './styles'
import Credits from '/components/Credits'
import Wavies from '/components/Wavies'
import WaviesFlipped from '/components/WaviesFlipped'
import Circles from '/components/Circles'
import Wipe from '/components/Wipe'
import HotlineModal from '/components/HotlineModal'
import { randomInt, scrollToPos } from '/helpers'
import { URLS } from '/constants'
import { get100vh } from '/vhgetter'

const COLORS = [
  // Blue
  [
    [45, 68, 95],
    [204, 234, 241],
  ],
  // Yellow
  [
    [255, 188, 0],
    [255, 228, 161],
  ],
  // Green
  [
    [27, 100, 81],
    [199, 227, 194],
  ],
  // Red
  [
    [215, 84, 67],
    [246, 203, 204],
  ],
]


const SKETCHES = [
  Wavies,
  WaviesFlipped,
  Circles,
  Wipe,
]

const SHAPES = [
  'squiggle',
  'triangle',
  'square',
  'circle',
  'cross',
]

class ScrollArrow extends React.Component {
  static defaultProps = {
    visibleThreshold: 75
  }

  onClick = (e) => {
    e.preventDefault()
    scrollToPos(this.props.distance + 1, 2, 3000)
  }

  render() {
    let svgStyle = {
      // transform: `translate(-50%, calc(-50% + ${50 * this.props.scroll}vh - ${2 * this.props.scroll}rem))`
    }
    return (
      <button onClick={this.onClick} className={Cx(styles.arrow, {
        [styles.upDownAnim]: this.props.scroll < this.props.visibleThreshold
      })}>
        <svg className={Cx(styles.downArrow)} style={svgStyle} viewBox="0 0 20 26">
          <line x1="10" y1="1" x2="10" y2="25"/>
          <polyline points="1,16 10,25 19,16 	"/>
        </svg>
      </button>
    )
  }
}

class RefreshButton extends React.Component {
  static defaultProps = {
    visibleThreshold: 75
  }

  onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (this.props.onRefresh) this.props.onRefresh()
    window.scrollTo(0, 0)
    window.location.reload()
  }

  render() {
    return (
      <button onClick={this.onClick} className={Cx(styles.refreshButton)}>
        <svg className={Cx(styles.refreshIcon)} viewBox="0 0 32 32">
          <polyline points="10.4,11.9 11.3,6.1 5.5,5.3 "/>
          <path d="M20.7,6c3.7,1.8,6.3,5.6,6.3,10c0,6.1-4.9,11-11,11S5,22.1,5,16c0-4.4,2.6-8.2,6.3-9.9"/>
        </svg>
      </button>
    )
  }
}

class Root extends React.Component {
  constructor(props) {
    super(props)
    this.scrollTick = false
    this.scrollY = 0
    this.mouseTick = false
    this.mouseX = 0
    this.mouseY = 0
    this.state = {
      color: randomInt(0, COLORS.length),
      sketch: randomInt(0, SKETCHES.length),
      shape: randomInt(1, SHAPES.length),
      atBottom: false,
      distance: this.getScrollDistance(),
      spacer: this.getSpacerHeight(),
      scrollExperience: 0,
      mouseX: 0.5,
      mouseY: 0.5,
    }
    this.updateHTMLBackground()
  }

  componentDidMount() {
    this.onResize()
    // Go to top!
    window.scrollTo(0, 0)
    // document.addEventListener('keyup', this.onKey)
    document.addEventListener('touchstart', this.onTouchStart)
    document.addEventListener('touchmove', this.onTouchMove)
    document.addEventListener('touchend', this.onTouchEnd)
    document.addEventListener('mousemove', this.onMouseMove)
    document.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount() {
    // document.removeEventListener('keyup', this.onKey)
    document.removeEventListener('touchstart', this.onTouchStart)
    document.removeEventListener('touchmove', this.onTouchMove)
    document.removeEventListener('touchend', this.onTouchEnd)
    document.removeEventListener('mousemove', this.onMouseMove)
    document.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  }

  onTouchStart = (e) => {
    this.startX = e.touches[0].clientX
    this.startY = e.touches[0].clientY
    this.startMouseX = this.state.mouseX
    this.startScrollY = this.scrollY
    this.dX = 0
    this.dY = 0
  }

  onTouchMove = (e) => {
    this.dX = this.startX - e.touches[0].clientX
    this.dY = this.startY - e.touches[0].clientY
    this.setState({
      mouseX: Math.max(0, Math.min(1, e.touches[0].clientX / window.innerWidth))
    })
  }

  onTouchEnd = () => {}

  onScroll = () => {
    this.scrollY = window.pageYOffset
    if (!this.scrollTick) {
      this.scrollTick = true
      window.requestAnimationFrame(this.updateScroll)
    }
  }

  onMouseMove = (e) => {
    this.mouseX = e.clientX
    this.mouseY = e.clientY
    if (!this.mouseTick) {
      this.mouseTick = true
      window.requestAnimationFrame(this.updateMouse)
    }
  }

  updateMouse = () => {
    this.setState({
      mouseX: this.mouseX / window.innerWidth,
      mouseY: this.mouseY / get100vh(),
    }, () => { this.mouseTick = false })
  }

  onResize = () => {
    this.setState({
      distance: this.getScrollDistance(),
      spacer: this.getSpacerHeight(),
    })
  }

  updateScroll = () => {
    this.scrollY = Math.max(0, this.scrollY)
    this.setState({
      scrollExperience: Math.max(0, Math.min(this.scrollY, this.state.distance)) / this.state.distance,
    }, () => { this.scrollTick = false })
  }

  updateHTMLBackground() {
    let bg = this.getColor()[0]
    document.documentElement.style.background = `rgb(${bg[0]},${bg[1]},${bg[2]})`
  }

  onClick = () => {
    this.setState({
      color: (this.state.color + 1) % COLORS.length
    }, () => this.updateHTMLBackground())
  }

  onKey = (e) => {
    let num = parseInt(e.key, 10)
    if (num && num - 1 < SKETCHES.length) {
      this.scrollY = 0
      this.setState({
        sketch: (num - 1) % SKETCHES.length,
        scrollExperience: 0,
      })
    }
    if (e.code === "KeyA") {
      console.log('shape 0')
      this.setState({ shape: 0 })
    }
    else if (e.code === "KeyS") {
      console.log('shape 1')
      this.setState({ shape: 1 })
    }
    else if (e.code === "KeyD") {
      console.log('shape 2')
      this.setState({ shape: 2 })
    }
    else if (e.code === "KeyF") {
      console.log('shape 3')
      this.setState({ shape: 3 })
    }
    else if (e.code === "KeyG") {
      console.log('shape 4')
      this.setState({ shape: 4 })
    }
  }

  getSketch() {
    return SKETCHES[this.state.sketch]
  }

  getColor() {
    return COLORS[this.state.color]
  }

  getBackgroundColor() {
    let bg = this.getColor()[1]
    if (this.state.sketch === 1 || this.state.sketch === 3) bg = this.getColor()[0]
    return bg
  }

  getTextColor() {
    let color = this.getColor()[0]
    // Yellow text should be darker
    if (this.state.color === 1) color = [226, 156, 16]
    // Flip color on some of the sketches
    if (this.state.sketch === 1 || this.state.sketch === 3) color = this.getColor()[1]
    color = `rgb(${color[0]},${color[1]},${color[2]})`
    return { color }
  }

  getShape() {
    if (this.state.sketch < 2) {
      return SHAPES[0]
    }
    if (this.state.sketch === 3) {
      return SHAPES[2]
    }
    return SHAPES[this.state.shape]
  }

  getShapesColor() {
    let color = this.getColor()[0]
    // Flip color on some of the sketches
    if (this.state.sketch === 1 || this.state.sketch === 3) color = this.getColor()[1]
    return `rgb(${color[0]},${color[1]},${color[2]})`
  }

  getShapesStyle() {
    return { background: this.getShapesColor() }
  }
  
  getScrollDistance() {
    return window.innerWidth < 768 ? 1000 : 2500
  }

  getSpacerHeight() {
    let distance = this.getScrollDistance()
    if (window.innerWidth < 768) {
      return distance + get100vh() + 50
    }
    return distance + get100vh() + 150
  }

  getScrollToPosition() {
    return window.innerWidth < 768 ? this.state.spacer : (this.state.distance + 150)
  }

  onRefresh = () => {
    if (this._gl.stop) this._gl.stop()
  }

  render() {
    let Component = this.getSketch()
    return <div onClick={this.onClick}>
      <div style={{ height: `${this.state.spacer}px` }} />
      <Component
        colors={this.getColor()}
        onEnterGoal={this.onEnterGoal}
        onLeaveGoal={this.onLeaveGoal}
        scroll={this.state.scrollExperience}
        mouseX={this.state.mouseX}
        mouseY={this.state.mouseY}
        ref={(c)=>{this._gl=c}} />
      <Credits
        shape={this.getShape()}
        shapesStyle={this.getShapesStyle()}
        shapesColor={this.getShapesColor()}
        style={this.getTextColor()}
        visible={this.state.scrollExperience >= 1}
        scroll={this.state.scrollCredits}
      />
      <ScrollArrow
        distance={this.getScrollToPosition()}
        progress={this.state.scrollExperience}
        scroll={this.scrollY} />
      {this.state.scrollExperience >= 1 && <RefreshButton onRefresh={this.onRefresh} />}
      <Route path={URLS.HOTLINE} render={(props) => {
        return <HotlineModal
          {...this.getTextColor()}
          background={this.getBackgroundColor()} />
      }} />
    </div>
  }
}

export default withRouter(Root)
