import React from 'react'

class BaseLink extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      over: false,
    }
  }

  onMouseEnter = () => {
    this.setState({ over: true })
  }

  onMouseLeave = () => {
    this.setState({ over: false })
  }
}

export default BaseLink