import React from 'react'
import Cx from 'classnames'
import styles from './styles'
import ShapeLink from './ShapeLink'
import { LINKS } from '/constants'

const SHAPES = {
  'triangle': ShapeLink,
  'square': ShapeLink,
  'circle': ShapeLink,
  'cross': ShapeLink,
  'squiggle': ShapeLink,
}

export default class extends React.Component {
  render() {
    return (
      <div className={Cx(styles.container, {
        [styles.visible]: this.props.visible
      })} ref={c=>{this._el=c}}>{
          LINKS.map((link, i) => {
            let Component = SHAPES[this.props.shape]
            if (Component) {
              return <div className={Cx(styles.shapeWrapper, { [styles.comingsoon]: link.comingsoon })} key={i}>
                <Component
                  className={Cx(styles.shape)}
                  {...link}
                  fill={this.props.color}
                  visible={this.props.visible}
                  shape={this.props.shape} />
              </div>
            }
            return null
          })
        }</div>
    )
  }
}