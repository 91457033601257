export const URLS = {
  HOTLINE: '/hotline',
}

export const PHONE = {
  number: '+1833GETTHUD',
  pretty: '(833) GET-THUD',
}

import dnafriend_image from '/assets/images/dna_friend.jpg'
import tacstorm_image from '/assets/images/cfg.jpg'
import sixminute_image from '/assets/images/personalitytest.jpg'
import ploog_image from '/assets/images/orfice.jpg'
import phone_image from '/assets/images/phone.jpg'
export const LINKS = [
  {
    url: 'https://dnafriend.com',
    img: dnafriend_image,
  },
  {
    url: 'https://sixminutetest.com',
    img: sixminute_image,
  },
  {
    url: 'https://meetploog.com',
    img: ploog_image,
  },
  {
    url: 'https://tacstorm.com',
    img: tacstorm_image,
  },
  {
    url: 'tel:' + PHONE.number,
    img: phone_image,
    hotline: true,
  },
]
