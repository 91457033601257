import React from 'react'
import Cx from 'classnames'
import styles from './styles'
import { TweenLite } from 'gsap'
import { TextureLoader, PlaneBufferGeometry, ShaderMaterial, Mesh, Vector2, Vector3, } from 'three'
import LogoApp from '/components/LogoApp'
import vertexShader from './vert.glsl'
import fragmentShader from './frag.glsl'

class Wipe extends LogoApp {
  updateUniforms(uniforms) {}

  getVertexShader() {
    return vertexShader
  }

  getFragmentShader() {
    return fragmentShader
  }
}

export default Wipe