import React from 'react'
import Cx from 'classnames'
import { withRouter } from 'react-router-dom'
import styles from './styles'
import Modal from '/components/Modal'
import { PHONE } from '/constants'

class Hotline extends React.Component {
  onCloseClick = () => {
    this.props.history.push('/')
  }

  render() {
    let bg = this.props.background
    let overlayStyle = {
      background: `rgba(${bg[0]}, ${bg[1]}, ${bg[2]}, 0.94)`
      ,
    }
    
    let textStyle = {
      color: this.props.color
    }
    
    let closeStyle = {
      stroke: this.props.color
    }
    let letters = PHONE.pretty.split('')
    return <Modal
      overlayClassName={styles.overlay}
      overlayStyle={overlayStyle}
      onOutsideClick={this.onCloseClick}>
      <div className={Cx(styles.phone)} style={textStyle}>
        <a href={'tel:' + PHONE.number}>{
          letters.map((o, i) => <span className={styles.char} style={{ animationDelay: (0.5 + i * 0.02) + 's'}} key={i}>{o}</span>)
        }</a>
      </div>
      <button className={styles.close} onClick={this.onCloseClick}>
        <svg viewBox="0 0 10 10" style={closeStyle}>
          <line x1="1" y1="1" x2="9" y2="9" />
          <line x1="9" y1="1" x2="1" y2="9" />
        </svg>
      </button>
    </Modal>
  }
}

export default withRouter(Hotline)