import React from 'react'
import Cx from 'classnames'
import styles from './styles'
import { isChrome } from '/helpers'

// Only chrome supports animating clip paths well and there is no obvious feature to test for.
const IS_CHROME = isChrome()

let COUNTER = 0

export default class extends React.Component {
  static defaultProps = {
    fill: 'pink',
    img: '',
    width: 100,
    rotation: 0,
    shape: 'triangle',
  }

  constructor(props) {
    super(props)
    // Unique ID
    this.line_id = this.props.shape + '_' + COUNTER
    COUNTER++
  }

    
  get width() {
    return this.props.width
  }

  get height() {
    return this.props.width
  }

  componentDidMount() {
    // Draw some points
    this.drawPoints()
    this.start()
    // this.tl = new TimelineMax({
    //   repeat: Infinity,
    //   yoyo: true,
    //   delay: Math.random(),
    //   paused: !this.props.visible || this.props.over,
    // })
    // this.tl.fromTo(this._svg, 2, {
    //   y: -5,
    // }, {
    //   y: 5,
    //   ease: Quad.easeInOut,
    // })
  }

  componentDidUpdate(prevProps) {
    // if (this.props.visible !== prevProps.visible) {
    //   this.props.visible ? this.tl.resume() : this.tl.pause()
    // }
    // if (this.props.imgVisible !== prevProps.imgVisible) {
    //   this.props.imgVisible ? this.tl.pause() : this.props.visible ? this.tl.resume() : this.tl.pause()
    // }
  }

  componentWillUnmount() {
    this.stop()
  }

  start() {
    this.started = Date.now()
    this.loop()
  }

  stop() {
    window.cancelAnimationFrame(this.raf)
  }

  loop = () => {
    this.raf = window.requestAnimationFrame(this.loop)
  }

  drawPoints() {
  }

  getPath() {
    if (this.props.shape === 'circle') {
      return "M50,11c21.5,0,39,17.5,39,39S71.5,89,50,89S11,71.5,11,50S28.5,11,50,11"
    }
    if (this.props.shape === 'square') {
      return "M15,15 85,15 85,85 15,85 15,15 "
    }
    if (this.props.shape === 'triangle') {
      return "M11,72.5 89,72.5 50,5 11,72.5 "
    }
    if (this.props.shape === 'cross') {
      return "M11,41h30V11h18v30h30v18H59v30H41V59H11V41"
    }
    if (this.props.shape === 'squiggle') {
      return `M33.8,17.2c3.6,2.8,8.8,1.1,10.1-3.3c1.8-6.1,10.5-6.1,12.3,0c1.3,4.3,6.5,6,10.1,3.3
      c5.1-3.9,12.1,1.2,10,7.2c-1.5,4.2,1.7,8.7,6.2,8.6c6.4-0.2,9.1,8.1,3.8,11.7c-3.7,2.5-3.7,8,0,10.6c5.3,3.6,2.6,11.9-3.8,11.7
      c-4.5-0.1-7.7,4.3-6.2,8.6c2.1,6-4.9,11.1-10,7.2c-3.6-2.8-8.8-1.1-10.1,3.3c-1.8,6.1-10.5,6.1-12.3,0c-1.3-4.3-6.5-6-10.1-3.3
      c-5.1,3.9-12.1-1.2-10-7.2c1.5-4.2-1.7-8.7-6.2-8.6c-6.4,0.2-9.1-8.1-3.8-11.7c3.7-2.5,3.7-8,0-10.6c-5.3-3.6-2.6-11.9,3.8-11.7
      c4.5,0.1,7.7-4.3,6.2-8.6C21.7,18.4,28.7,13.3,33.8,17.2`
    }
    return ''
  }
  
  render() {
    let rotation = this.props.rotation
    let rotateBy = this.props.imgVisible && IS_CHROME ? (this.props.shape === 'triangle' ? 120 : 180) : 0
    return (
      <svg
        className={Cx(styles.container, styles[this.props.shape], {
          [styles.chrome]: IS_CHROME,
          [styles.over]: this.props.imgVisible,
          [styles.visible]: this.props.visible,
          [styles.inactive]: this.props.inactive,
        })}
        ref={c=>{this._svg=c}}
        width={this.width}
        height={this.height}
        viewBox="0 0 100 100">
        <defs>
          <clipPath id={this.line_id}>
            <path
              className={styles.mask}
              d={this.getPath()}
              fill="white"
              transform={`rotate(${rotation + rotateBy} 50 50)`} />
          </clipPath>
        </defs>
        {!this.props.inactive &&
          <g  clipPath={'url(#' + this.line_id + ')'}>
            <rect
              className={styles.colorRect}
              x={-1}
              y={-1}
              width={102}
              height={102}
              fill={this.props.fill} />
            {(this.props.img && !this.props.inactive) &&
              <image
                className={styles.image}
                xlinkHref={this.props.img}
                x={0}
                y={0}
                width={100}
                height={100}
                opacity={this.props.imgVisible}
                preserveAspectRatio="xMidYMid slice" />
            }
          </g>
        }
        {this.props.inactive &&
          <path
            className={styles.emptyPath}
            d={this.getPath()}
            stroke={this.props.fill}
            fill="none"
            transform={`rotate(${rotation} 50 50)`} />
        }
      </svg>
    )
  }
}