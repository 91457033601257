export function randomInt(start=0, end) {
  return start + Math.floor(Math.random() * (end - start))
}

export function randomNChunks(num, whole=1) {
  let chunks = []
  let total = 0
  for (let i=0; i<num; i++) {
    let chunk = Math.random()
    chunks.push(chunk)
    total += chunk
  }
  return chunks.map(c => whole * c / total)
}

export function ease(value, goal, strength = 0.08) {
  return value + (goal - value) * strength
}

export function getCoverDimensions(aspect, win_w, win_h) {
  let width,
    height,
    offset_left,
    offset_top,
    win_a = win_w / win_h
  if (win_a < aspect) {
    width = win_h * aspect
    height = win_h
    offset_left = (win_w - width) * 0.5
    offset_top = 0
  } else {
    width = win_w
    height = win_w / aspect
    offset_top = (win_h - height) * 0.5
    offset_left = 0
  }
  return [width, height, offset_left, offset_top]
}

export function getContainDimensions(aspect, win_w, win_h) {
  let width,
    height,
    offset_left,
    offset_top,
    win_a = win_w / win_h
  if (win_a < aspect) {
    width = win_w
    height = win_w / aspect
    offset_top = (win_h - height) * 0.5
    offset_left = 0
  } else {
    width = win_h * aspect
    height = win_h
    offset_left = (win_w - width) * 0.5
    offset_top = 0
  }
  return [width, height, offset_left, offset_top]
}
export function pad(n, width=2, z=0) {
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

// MS Edge
export function isEdge() {
  return navigator.userAgent.toLowerCase().indexOf('edge/') > -1
}

export function isChrome() {
  return navigator.userAgent.toLowerCase().indexOf('chrome/') > -1 && !isEdge()
}

// iOS
export function isIOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent)
}

// iOS Chrome
export function isIOSChrome() {
  return /CriOS/.test(navigator.userAgent)
}

// Android
export function isAndroid() {
  return /Android/i.test(navigator.userAgent)
}

export function isWindowsTablet() {
  return (navigator.userAgent.toLowerCase().indexOf('windows nt') !== -1 &&
    navigator.userAgent.toLowerCase().indexOf('touch') !== -1)
}


// Detect both iOS and Android
export function isMobileOS() {
  return isIOS() || isAndroid() || isWindowsTablet()
}

// Scroll To
import { TweenLite } from 'gsap'

const SCROLL_POS = {
  x: 0,
  y: 0
}

export function scrollToPos(to, duration=0.75, maxScroll=1500) {
  // Clean Up Previous Scrolls
  TweenLite.killTweensOf(SCROLL_POS)
  if (Math.abs(to - window.pageYOffset) > maxScroll) {
    window.scrollTo(0, to + Math.sign(window.pageYOffset - to) * maxScroll)
  }
  SCROLL_POS.y = window.pageYOffset

  // Animate Scroll
  TweenLite.to(SCROLL_POS, duration, {
    y: to,
    ease: Cubic.easeOut,
    onUpdate() {
      window.scrollTo(SCROLL_POS.x, SCROLL_POS.y)
    }
  })
}