// Setup Tester Element
const sizer = document.createElement('div')
sizer.style.position = 'fixed'
sizer.style.top = 0
sizer.style.left = 0
sizer.style.width = 0
sizer.style.height = '100vh'
sizer.style.zInded = -1
document.body.appendChild(sizer)

// Test for iOS Chrome
let vh100 = 0

function compute100vh() {
  vh100 = sizer.getBoundingClientRect().height
}

compute100vh()

window.addEventListener('resize', () => {
  compute100vh()
})

export function get100vh() {
  return vh100
}